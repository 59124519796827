import React from "react"
import Container from "@ecom/ui/components/Container"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"

import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Link from "@material-ui/core/Link"

import { QUESTIONS } from "./helpers"
import * as styles from "./questions.module.scss"
import { pushToDataLayerOnce } from "../../helpers/pushToDataLayerOnce"

export type QuestionsList = {
  question: string
  answer: string
}

export type QuestionsProps = {
  questionList?: QuestionsList[]
  orderNum?: string
  withButtons?: boolean
}

const handleClick = () => {
  pushToDataLayer({
    event: "buttonClick",
    name: "Участвовать",
    placement: "qustions_button_halva",
  })
}

export const Questions = ({
  questionList = QUESTIONS,
  orderNum,
  withButtons = false,
}: QuestionsProps) => {
  const [expanded, setExpanded] = React.useState<string | false>(QUESTIONS[0].question || false)

  const handleChange = (panel: string) => (_: any, isExpanded: boolean) => {
    if (isExpanded) {
      pushToDataLayerOnce("open_faq", { event: "buttonClick", name: "open_faq" })
    }

    setExpanded(isExpanded ? panel : false)
  }

  return (
    <section className={styles.section} id="questions" data-exclude={orderNum}>
      <Container>
        <h3 className={styles.title}>Вопросы и ответы</h3>
        {questionList?.map((q) => (
          <Accordion
            key={q.question}
            onChange={handleChange(q.question)}
            expanded={expanded === q.question}
            classes={{
              root: styles.accordion_root,
              expanded: styles.accordion_root_expanded,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              classes={{
                root: styles.summary_root,
                expandIcon: styles.expand_icon,
                content: styles.summary_content,
              }}
            >
              {q.question}
            </AccordionSummary>
            <AccordionDetails
              classes={{
                root: styles.details_root,
              }}
            >
              <div className={styles.answers} dangerouslySetInnerHTML={{ __html: q.answer }} />
            </AccordionDetails>
          </Accordion>
        ))}
        {withButtons && (
          <div className={styles.withButtons}>
            <Link
              href="https://halvacard.ru/shops/actions/otkryvaem-shubohranilishe"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.btn}
              underline="none"
              onClick={handleClick}
            >
              Участвовать
            </Link>
          </div>
        )}
      </Container>
    </section>
  )
}
